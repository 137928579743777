export default {
  namespaced: true,
  state: {
    me: {},
  },
  getters: {
    me: state => state.me,
  },
  mutations: {
    get(state) {
      if (!state.me?._id) {
        this._vm.$http.get('/me')
          .then(({ user }) => {
            state.me = user
          }).catch(err => {
            console.log(err)
          })
      }
    },
  },
  actions: {
    get({ commit }) {
      commit('get')
    },
  },
}
