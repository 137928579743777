import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'
import LoadScript from 'vue-plugin-load-script'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import VJsoneditor from 'v-jsoneditor'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import './registerServiceWorker'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(LoadScript)
Vue.use(VueTelInput)
Vue.use(VJsoneditor)

Vue.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDl1SFay6hcdIFEtHgwLHpuwt9x3OD9CIc&libraries=places')

Vue.prototype.$moment = moment

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
