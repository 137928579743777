import Vue from 'vue'
import VueRouter from 'vue-router'
import admin from '@/middlewares/admin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/ecommerce/Dashboard.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Home',
        access: 'full',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/chiffre-boutique',
      name: 'vendor',
      component: () => import('@/views/ecommerce/Vendor.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Mes performances',
        breadcrumb: [
          {
            text: 'Mes performances',
            active: true,
          },
        ],
      },
    },
    {
      path: '/vendor-detail',
      name: 'vendor-detail',
      component: () => import('@/views/ecommerce/VendorDetail.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Mes performances',
        breadcrumb: [
          {
            text: 'Mes performances',
            active: true,
          },
        ],
      },
    },
    {
      path: '/return',
      name: 'return',
      component: () => import('@/views/ReturnList.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Retour client',
        breadcrumb: [
          {
            text: 'Retour client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/alerts',
      name: 'alerts',
      component: () => import('@/views/Alerts.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Alertes sites',
        breadcrumb: [
          {
            text: 'Alertes sites',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/ecommerce/Products.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Produits',
        access: 'full',
        breadcrumb: [
          {
            text: 'Détail produit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products-v2',
      name: 'productsV2',
      component: () => import('@/views/ecommerce/ProductsV2.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Produits',
        access: 'full',
        breadcrumb: [
          {
            text: 'Détail produit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products-faq',
      name: 'productsFaq',
      component: () => import('@/views/ecommerce/ProductsFaq.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Produits',
        access: 'full',
        breadcrumb: [
          {
            text: 'Faq produit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/page-boutique',
      name: 'page-boutique',
      component: () => import('@/views/PageBoutique.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Page boutique',
        breadcrumb: [
          {
            text: 'Page boutique',
            active: true,
          },
        ],
      },
    },
    {
      path: '/counter',
      name: 'counter',
      component: () => import('@/views/Counter.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Counter',
        access: 'sales',
        breadcrumb: [
          {
            text: 'Counter',
            active: true,
          },
        ],
      },
    },
    {
      path: '/upload',
      name: 'upload',
      component: () => import('@/views/Upload.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Upload',
        breadcrumb: [
          {
            text: 'Upload',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customer-alert',
      name: 'customerAlert',
      component: () => import('@/views/AlertCustomerStock.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Customer Alert',
        breadcrumb: [
          {
            text: 'Customer Alert',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product-textile',
      name: 'productTextile',
      component: () => import('@/views/ecommerce/ProductsTextile.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Textile Product',
        breadcrumb: [
          {
            text: 'Textile Product',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stock',
      name: 'stock',
      component: () => import('@/views/Stock.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Stock',
        breadcrumb: [
          {
            text: 'Stock',
            active: true,
          },
        ],
      },
    },

    {
      path: '/stock-online',
      name: 'stock-online',
      component: () => import('@/views/StockOnline.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'StockOnline',
        breadcrumb: [
          {
            text: 'StockOnline',
            active: true,
          },
        ],
      },
    },
    {
      path: '/vente',
      name: 'vente',
      component: () => import('@/views/Vente.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Vente',
        breadcrumb: [
          {
            text: 'Vente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dispatch',
      name: 'dispatch',
      component: () => import('@/views/Dispatch.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Dispatch',
        breadcrumb: [
          {
            text: 'Dispatch',
            active: true,
          },
        ],
      },
    },
    {
      path: '/guide',
      name: 'guide',
      component: () => import('@/views/Guide.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Guide',
        breadcrumb: [
          {
            text: 'Guide',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sea',
      name: 'sea',
      component: () => import('@/views/Sea.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'SEA',
        access: 'full',
        breadcrumb: [
          {
            text: 'SEA',
            active: true,
          },
        ],
      },
    },
    {
      path: '/items-dispatch',
      name: 'itemsDispatch',
      component: () => import('@/views/ItemsDispatch.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Dispatch',
      },
    },
    {
      path: '/logistic-warning',
      name: 'logisticWarning',
      component: () => import('@/views/LogisticWarning.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Logistic Alerte',
      },
    },
    {
      path: '/logistic-dispatch',
      name: 'logisticDispatch',
      component: () => import('@/views/LogisticDispatch.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Logistic Dispatch Files',
      },
    },
    {
      path: '/logistic-details',
      name: 'logisticDetails',
      component: () => import('@/views/LogisticDetails.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Logistic Details',
      },
    },
    {
      path: '/logistic-order',
      name: 'logisticOrder',
      component: () => import('@/views/LogisticOrder.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Logistic Commande',
      },
    },
    {
      path: '/sinistre-order',
      name: 'sinistreOrder',
      component: () => import('@/views/SinistreOrder.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Sinistre Commande',
      },
    },
    {
      path: '/logistic-stock',
      name: 'logisticStock',
      component: () => import('@/views/LogisticStock.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Logistic Commande',
      },
    },
    {
      path: '/solution-list',
      name: 'solutionList',
      component: () => import('@/views/SolutionsList.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Liste de solution ticket',
      },
    },
    {
      path: '/solution-create',
      name: 'solutionCreate',
      component: () => import('@/views/SolutionsCreate.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Créateur de solution ticket',
      },
    },
    {
      path: '/commande',
      name: 'commande',
      component: () => import('@/views/Commande.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Commande',
        breadcrumb: [
          {
            text: 'Commande',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gorgias',
      name: 'gorgias',
      component: () => import('@/views/Gorgias.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Gorgias',
        breadcrumb: [
          {
            text: 'Gorgias',
            active: true,
          },
        ],
      },
    },
    {
      path: '/echouees',
      name: 'echouees',
      component: () => import('@/views/Echouees.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Echouées',
        breadcrumb: [
          {
            text: 'Echouées',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report-failed',
      name: 'report-failed',
      component: () => import('@/views/ReportFailed.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Rapport Echouées',
        breadcrumb: [
          {
            text: 'Rapport Echouées',
            active: true,
          },
        ],
      },
    },
    {
      path: '/datas',
      name: 'datas',
      component: () => import('@/views/Datas.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Datas',
        breadcrumb: [
          {
            text: 'Datas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('@/views/Blog.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Blog',
        breadcrumb: [
          {
            text: 'Blog',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('@/views/Categories.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Categories',
        breadcrumb: [
          {
            text: 'Categories',
            active: true,
          },
        ],
      },
    },
    {
      path: '/encaissement',
      name: 'encaissement',
      component: () => import('@/views/Encaissement.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Encaissement',
        access: 'sales',
        breadcrumb: [
          {
            text: 'Encaissement',
            active: true,
          },
        ],
      },
    },
    {
      path: '/encaissement-sav',
      name: 'encaissementsav',
      component: () => import('@/views/EncaissementSav.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Création de commande',
        access: 'sales',
        breadcrumb: [
          {
            text: 'Création de commande',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ecommerce',
      name: 'ecommerce',
      component: () => import('@/views/ecommerce/EcommerceMargin.vue'),
      meta: {
        middleware: admin,
        access: 'full',
        pageTitle: 'ECommerce',
        breadcrumb: [
          {
            text: 'ECommerce',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/devis',
      name: 'devis',
      component: () => import('@/views/Devis.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Devis',
        breadcrumb: [
          {
            text: 'Devis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/search-data',
      name: 'search-data',
      component: () => import('@/views/SearchData.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Search Data',
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/calendar/Calendar.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Calendar',
        breadcrumb: [
          {
            text: 'Calendar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commande/:id',
      name: 'commandeDetail',
      component: () => import('@/views/CommandeDetail.vue'),
      meta: {
        middleware: admin,
        pageTitle: "Détail d'une commande",
        breadcrumb: [
          {
            text: "Détail d'une commande",
            active: true,
          },
        ],
      },
    },
    {
      path: '/commande/:id/facture',
      name: 'factureDetail',
      component: () => import('@/views/FactureDetail.vue'),
      meta: {
        middleware: admin,
        pageTitle: "Facture d'une commande",
        breadcrumb: [
          {
            text: "Facture d'une commande",
            active: true,
          },
        ],
      },
    },
    {
      path: '/commande/:id/claims',
      name: 'claims-order',
      component: () => import('@/views/ClaimDetail.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Order Claims',
        breadcrumb: [
          {
            text: 'Order Claims',
            active: true,
          },
        ],
      },
    },
    {
      path: '/coupon',
      name: 'coupon',
      component: () => import('@/views/Coupon.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Code promo',
        breadcrumb: [
          {
            text: 'Code promo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/logistic',
      name: 'logistic',
      component: () => import('@/views/Logistic.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Logistique',
        access: 'logistic',
        breadcrumb: [
          {
            text: 'Logistique',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/logs',
    //   name: 'logs',
    //   component: () => import('@/views/Log.vue'),
    //   meta: {
    //     middleware: admin,
    //     pageTitle: 'Logs',
    //     breadcrumb: [
    //       {
    //         text: 'Logs',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: '/failed-paiement',
      name: 'Paiement échoués',
      component: () => import('@/views/PaiementEchoues.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Paiement échoués',
        breadcrumb: [
          {
            text: 'Paiement échoués',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order-upload',
      name: 'importOrder',
      component: () => import('@/views/OrderUpload.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Suivi Order',
        breadcrumb: [
          {
            text: 'Suivi Order',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bl-upload',
      name: 'importbl',
      component: () => import('@/views/BlUpload.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Suivi BL',
        breadcrumb: [
          {
            text: 'Suivi BL',
            active: true,
          },
        ],
      },
    },
    {
      path: '/compose-upload',
      name: 'importcompose',
      component: () => import('@/views/ComposeLogUpload.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Import Compose Sku',
        breadcrumb: [
          {
            text: 'Import Compose Sku',
            active: true,
          },
        ],
      },
    },
    {
      path: '/delivery-upload',
      name: 'develiryupload',
      component: () => import('@/views/DeliveryUpload.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Delivery Upload',
        breadcrumb: [
          {
            text: 'Delivery Upload',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders-out-of-stock',
      name: 'ordersOutOfStock',
      component: () => import('@/views/OrderOutOfStock.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Out of stock orders',
        breadcrumb: [
          {
            text: 'Out of stock orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders-info-problem',
      name: 'ordersInfoProblem',
      component: () => import('@/views/OrderInfoProblem.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Info problem orders',
        breadcrumb: [
          {
            text: 'Info problem orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/export-compta',
      name: 'exportCompta',
      component: () => import('@/views/ExportCompta.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Export comptable',
        breadcrumb: [
          {
            text: 'Export comptable',
            active: true,
          },
        ],
      },
    },
    {
      path: '/claims',
      name: 'claims',
      component: () => import('@/views/ClaimList.vue'),
      meta: {
        middleware: admin,
        pageTitle: 'Claim List',
        breadcrumb: [
          {
            text: 'Claim List',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/influenceurs',
    //   name: 'influenceur',
    //   component: () => import('@/views/Influenceur.vue'),
    //   meta: {
    //     middleware: admin,
    //     pageTitle: 'Influenceur',
    //     breadcrumb: [
    //       {
    //         text: 'Influenceur',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/todo',
      name: 'todo',
      component: () => import('@/views/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
    },
    {
      path: '/todo/:filter',
      name: 'todo-filter',
      component: () => import('@/views/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        navActiveLink: 'todo',
      },
      // beforeEnter(to, _, next) {
      //   if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      //   else next({ name: 'error-404' })
      // },
    },
    {
      path: '/todo/tag/:tag',
      name: 'todo-tag',
      component: () => import('@/views/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        navActiveLink: 'todo',
      },
      // beforeEnter(to, _, next) {
      //   if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      //   else next({ name: 'error-404' })
      // },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
