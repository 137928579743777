const get = key => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key))
  }
  return false
}

const update = (data, key) => {
  localStorage.setItem(key, JSON.stringify(data))
}

const remove = key => {
  localStorage.removeItem(key)
}

export default {
  get,
  update,
  remove,
}
