export default function auth({ next, router }) {
  if (!localStorage.getItem('accessToken')) {
    return router.push('/login')
  }
  const route = router.options.routes.find(r => window.location.pathname === (r?.path))
  if (localStorage.getItem('type') === 'logistic' && route?.meta?.access !== 'logistic' && route.path !== '/logistic') {
    window.location = '/logistic'
  }
  if (localStorage.getItem('type') === 'sales' && route?.meta?.access !== 'sales' && !['/encaissement', '/counter'].includes(route.path)) {
    window.location = '/encaissement'
  }
  if (localStorage.getItem('type') === 'external' && route?.meta?.access !== 'full' && route.path !== '/') {
    window.location = '/'
  }
  return next()
}
