/* eslint-disable no-confusing-arrow */
import storage from '@utils/storage.js'

const name = 'order'
export default {
  namespaced: true,
  state: {
    order: {
      form: {
        billing: {
          first_name: null,
          last_name: null,
          address_1: null,
          address_2: null,
          city: null,
          state: null,
          postcode: null,
          country: null,
          email: null,
          phone: null,
          phone_code: null,
        },
        shipping: {
          first_name: null,
          last_name: null,
          address_1: null,
          address_2: null,
          city: null,
          state: null,
          postcode: null,
          country: null,
          email: null,
          phone: null,
          phone_code: null,
          company: null,
          tva: null,
          refer_to: null,
          etage: null,
          ascenseur: null,
        },
        mailchimp_woocommerce_newsletter: false,
        customer_note: null,
        'terms-field': true,
      },
      shipping: {
        country: null,
        phone: null,
      },
      billing: {
        country: null,
        phone: null,
      },
      hasOtherBilling: false,
      hasOtherAddress: false,
      hasBillingOtherAddress: false,
      hasCompany: false,
      shippingDate: false,
      dateSelect: null,
    },
  },
  getters: {
    order: state => state.order,
  },
  mutations: {
    set(state, data) {
      state.order = data
      storage.update(data, name)
    },
    remove(state) {
      state.order = {
        form: {
          billing: {
            first_name: null,
            last_name: null,
            address_1: null,
            address_2: null,
            city: null,
            state: null,
            postcode: null,
            country: null,
            email: null,
            phone: null,
            phone_code: null,
          },
          shipping: {
            first_name: null,
            last_name: null,
            address_1: null,
            address_2: null,
            city: null,
            state: null,
            postcode: null,
            country: null,
            email: null,
            phone: null,
            phone_code: null,
            company: null,
            tva: null,
            refer_to: null,
            etage: null,
            ascenseur: null,
          },
          mailchimp_woocommerce_newsletter: false,
          customer_note: null,
          'terms-field': true,
        },
        shipping: {
          country: null,
          phone: null,
        },
        billing: {
          country: null,
          phone: null,
        },
        hasOtherBilling: false,
        hasOtherAddress: false,
        hasBillingOtherAddress: false,
        hasCompany: false,
      }
      storage.remove(name)
    },
  },
  actions: {
    async get({ commit, state }) {
      return new Promise(resolve => {
        if (storage.get(name)) {
          commit('set', storage.get(name))
          resolve(storage.get(name))
        }
        resolve(state.order)
      })
    },
    set({ commit }, data) {
      commit('set', data)
    },
    remove({ commit }) {
      commit('remove')
    },
  },
}
